.not-member {
  background-color: #6c757d !important;
  color: white;
  padding: 4px 8px;
  font-size: 10px;
  text-align: center;
  border-radius: 5px;
}

.member {
  background-color: #5bc0de !important;
  color: white;
  padding: 4px 8px;
  font-size: 10px;
  text-align: center;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .mission-header {
    width: 85%;
    justify-content: space-between;
  }

  .member-status {
    white-space: nowrap;
  }

  .leave-btn,
  .join-btn {
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
  }

  .leave-btn {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .join-btn {
    background-color: white;
    border-color: #6c757d;
    color: #6c757d;
  }
}
