a,
a:hover {
  text-decoration: none;
}

header {
  top: 0;
  z-index: 10;
  background-color: white;
}

button:focus {
  box-shadow: none !important;
}

.description {
  text-align: justify;
}
